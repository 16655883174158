import { HashLink } from "react-router-hash-link";

interface ListItem {
  label: string;
  url: string;
}

const listItems: ListItem[] = [
  { label: "Home", url: "/#home" },
  { label: "About us", url: "/#about" },
  { label: "Privacy Policy", url: "/privacy" },
  { label: "Refund Policy", url: "/refund" },
  { label: "Contact us", url: "/contact" },
  { label: "Terms & Conditions", url: "/tnc" },
  { label: "Support", url: "/support" },
];

const { REACT_APP_S3_URL } = process.env;

const Sidebar = () => {
  return (
    <section className="hidden xl:flex">
      <div className="flex h-screen w-[320px] flex-col justify-between border border-y-0 border-primary bg-secondary p-6">
        <a href="/">
          <img
            src={`${REACT_APP_S3_URL}/assets/one-life-logo-white.png`}
            alt="one-life-logo"
            className="w-56 opacity-80"
          />
        </a>
        <div className="text-wrap text-left font-community text-[12px] text-[#aaaaaa]">
          <ul className="flex flex-wrap items-center justify-start gap-x-4 pb-1">
            {listItems.map((item, index) => (
              <li className="hover:scale-105 hover:opacity-70" key={index}>
                {item.url.startsWith("/#") ? (
                  <HashLink smooth to={item.url}>
                    {item.label}
                  </HashLink>
                ) : (
                  <a href={item.url}>{item.label}</a>
                )}
              </li>
            ))}
          </ul>
          <p>© Fly Fare Technologies LLP 2024. All rights reserved.</p>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
