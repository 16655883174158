import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaInfoCircle,
  FaBlog,
  FaUserMd,
  FaPhoneAlt,
  FaHandHoldingHeart,
} from "react-icons/fa";
interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const menuItems = [
    { label: "Home", path: "/#home", icon: <FaHome /> },
    { label: "About Us", path: "/#about", icon: <FaInfoCircle /> },
    { label: "Blogs", path: "/blog", icon: <FaBlog /> },
    { label: "Contact Us", path: "/contact", icon: <FaPhoneAlt /> },
    { label: "Space", path: "/space", icon: <FaHandHoldingHeart /> },
    { label: "Therapists", path: "/consultation", icon: <FaUserMd /> },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 z-40 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      <div className="relative h-full w-64 bg-secondary px-8 pt-12 text-light">
        <ul className="mt-10 space-y-5">
          {menuItems.map((item) => (
            <li
              key={item.label}
              className="flex cursor-pointer items-center gap-x-3 text-lg"
              onClick={() => handleNavigation(item.path)}
            >
              {item.icon}
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
