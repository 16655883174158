import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaRegBookmark,
  FaBookmark,
  FaHeart,
  FaUserCircle,
  FaRegHeart,
  FaCaretDown,
  FaLink,
  FaRegEye,
  FaRegComment,
  FaShareAlt,
} from "react-icons/fa";
import { RootState } from "../store";
import { formatRelativeTime } from "../utils/dateUtils";
import PostSkeleton from "./PostSkeleton";
import { Tooltip } from "react-tooltip";

interface Post {
  _id: string;
  username: string;
  title: string;
  body: string;
  userId: string;
  likes: number;
  views: number;
  commentCount: number;
  status: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  liked: boolean;
  bookmarked: boolean;
}

const { REACT_APP_API_URL, REACT_APP_URL } = process.env;

const Feed: React.FC = () => {
  const navigate = useNavigate();
  const [limit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sortOption, setSortOption] = useState("top");
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shareDropDownOpen, setShareDropDownOpen] = useState<string | null>(
    null,
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const shareDropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const fetchPosts = async (sort: string, tags?: string) => {
    try {
      let tagsArray: string[] = [];

      if (tags) {
        tagsArray = tags.split(",");
        tagsArray = tagsArray.map((tag) => tag.replace(/-/g, " "));
        tagsArray = tagsArray.map((tag) => tag.replace(/\s+/g, "-"));
      }

      const tagsParams = tagsArray.map((tag) => `tags=${tag}`).join("&");
      if (page > 1) {
        setIsLoadingMore(true);
      } else {
        setLoading(true);
      }
      setTimeout(async () => {
        try {
          const response = await axios.get(
            `${REACT_APP_API_URL}/post/all?sort=${sort}&limit=${limit}&page=${page}${tags ? `&${tagsParams}` : ""}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          const fetchedPosts = response.data.data;
          setPosts((prevPosts) =>
            page === 1 ? fetchedPosts : [...prevPosts, ...fetchedPosts],
          );
          setPage((prevPage) => prevPage + 1);

          if (fetchedPosts.length < limit) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } catch (error) {
          setError("Failed to fetch posts");
        } finally {
          if (page > 1) {
            setIsLoadingMore(false);
          } else {
            setLoading(false);
          }
        }
      }, 200);
    } catch (error) {
      setError("Failed to fetch posts");
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleLoadMore = () => {
    fetchPosts(sortOption);
    loadMoreRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareDropdownRef.current &&
        !shareDropdownRef.current.contains(event.target as Node)
      ) {
        setShareDropDownOpen(null);
      }
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shareDropDownOpen]);

  useEffect(() => {
    const storedSortOption = localStorage.getItem("sortOption");
    if (storedSortOption) {
      setSortOption(storedSortOption);
    }
    const params = new URLSearchParams(location.search);
    const tags = params.getAll("tags");
    fetchPosts(storedSortOption || sortOption, tags.join(","));
  }, [token, location.search]);

  useEffect(() => {
    console.log("Redux State:", { posts, loading, error, page });
  }, [posts, loading, error, page]);

  const handleSortChange = (sort: string) => {
    setSortOption(sort);
    localStorage.setItem("sortOption", sort);
    setLoading(true);
    setDropdownOpen(false);
    setPage(1); // Reset the page to 1 when the sort option changes
    const params = new URLSearchParams(location.search);
    const tags = params.get("tags");
    fetchPosts(sort, tags || undefined);
  };

  const handleShareClick = (postId: string) => {
    if (shareDropDownOpen === postId) {
      setShareDropDownOpen(null);
    } else {
      setShareDropDownOpen(postId);
    }
  };

  const handleCopyLink = (postId: string) => {
    const post = posts.find((p) => p._id === postId);
    if (post) {
      navigator.clipboard
        .writeText(`${REACT_APP_URL}/space/post/${post._id}`)
        .then(() => {
          toast.success("Link copied to clipboard!");
          setShareDropDownOpen(null);
        })
        .catch((error) => {
          console.error("Failed to copy link:", error);
          toast.error("Failed to copy link. Please try again.");
        });
    } else {
      toast.error("Post not found.");
    }
  };

  const handleLike = async (postId: string) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    try {
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
                ...post,
                liked: !post.liked,
                likes: post.liked ? post.likes - 1 : post.likes + 1,
              }
            : post,
        ),
      );
      await axios.post(
        `${REACT_APP_API_URL}/post/like/${postId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      toast.error("Failed to like post. Please try again later.");
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
                ...post,
                liked: !post.liked,
                likes: post.liked ? post.likes - 1 : post.likes + 1,
              }
            : post,
        ),
      );
    }
  };

  const handleBookmark = async (postId: string) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    try {
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post._id === postId) {
            const newBookmarkedStatus = !post.bookmarked;
            toast.success(
              newBookmarkedStatus
                ? "Added to your Bookmarks"
                : "Removed from your Bookmarks",
            );
            return { ...post, bookmarked: newBookmarkedStatus };
          }
          return post;
        }),
      );
      await axios.post(
        `${REACT_APP_API_URL}/post/bookmark/${postId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      toast.error("Failed to add to your Bookmarks. Please try again later.");
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post._id === postId) {
            const newBookmarkedStatus = !post.bookmarked;
            return { ...post, bookmarked: newBookmarkedStatus };
          }
          return post;
        }),
      );
    }
  };

  if (loading) {
    return (
      <div className="hide-scrollbar m-auto h-screen w-[90%] overflow-y-auto rounded-lg pb-28 font-community xl:mx-5 xl:w-[60%]">
        <p className="mb-2 ml-1 text-2xl font-bold tracking-wide text-light xl:hidden">
          Speak It Out!
        </p>
        <div className="mb-2 flex items-center text-sm xl:mb-1 xl:mt-0">
          <span className="flex-grow border-b border-primary"></span>
          <span className="ml-2 mr-2 text-[#7a7a7a]">Sort by:</span>
          <div className="relative" ref={dropdownRef}>
            <div
              className="flex cursor-pointer items-center text-tertiary"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {sortOption === "top" ? "Top" : "Recent"}
              <FaCaretDown className="ml-2 text-tertiary" />
            </div>
            {dropdownOpen && (
              <div className="absolute right-0 z-50 mt-2 w-32 rounded-md bg-secondary shadow-xl">
                <div
                  className="block cursor-pointer rounded-md px-4 py-2 text-tertiary hover:opacity-70"
                  onClick={() => handleSortChange("top")}
                >
                  Top
                </div>
                <div className="border-b border-[#99999941]"></div>
                <div
                  className="block cursor-pointer rounded-md px-4 py-2 text-tertiary hover:opacity-70"
                  onClick={() => handleSortChange("recent")}
                >
                  Recent
                </div>
              </div>
            )}
          </div>
        </div>
        {[...Array(5)].map((_, index) => (
          <PostSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="mx-5 h-screen rounded-lg drop-shadow xl:w-[60%]">
        {error}
      </div>
    );
  }

  return (
    <div className="hide-scrollbar m-auto h-auto min-h-screen w-[90%] rounded-lg font-community xl:mx-5 xl:h-screen xl:w-[60%] xl:overflow-y-auto xl:pb-28">
      <p className="mb-2 ml-1 text-2xl font-bold tracking-wide text-light xl:hidden">
        Speak It Out!
      </p>
      <div className="mb-2 flex items-center text-sm xl:mb-1 xl:mt-0">
        <span className="flex-grow border-b border-primary"></span>
        <span className="ml-2 mr-2 text-[#7a7a7a]">Sort by:</span>
        <div className="relative" ref={dropdownRef}>
          <div
            className="flex cursor-pointer items-center text-tertiary"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {sortOption === "top" ? "Top" : "Recent"}
            <FaCaretDown className="ml-2 text-tertiary" />
          </div>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-32 rounded-md bg-secondary shadow-xl">
              <div
                className="block cursor-pointer rounded-md px-4 py-2 text-tertiary hover:opacity-70"
                onClick={() => handleSortChange("top")}
              >
                Top
              </div>
              <div className="border-b border-[#99999941]"></div>
              <div
                className="block cursor-pointer rounded-md px-4 py-2 text-tertiary hover:opacity-70"
                onClick={() => handleSortChange("recent")}
              >
                Recent
              </div>
            </div>
          )}
        </div>
      </div>
      {posts.length === 0 ? (
        <div className="my-4 text-center text-base text-tertiary">
          No posts found.
        </div>
      ) : (
        posts.map((post) => (
          <div
            key={post._id}
            className="mb-3 cursor-pointer rounded-xl border border-primary bg-primary px-4 pt-4 shadow-md hover:bg-secondary"
            onClick={() => {
              navigate(`/space/post/${post._id}`);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <div className="mb-2 flex items-center justify-between ">
              <div className="flex items-center">
                <FaUserCircle className="mr-2 h-6 w-6 text-light" />
                <span className="font-semibold text-light">
                  {post.username ? post.username : "Anonymous"}
                </span>
              </div>
              <div className="flex items-center">
                <span className="mr-2 text-[12px] text-tertiary">
                  {formatRelativeTime(post.createdAt)}
                </span>
                <div
                  className="text-tertiary hover:scale-105 hover:opacity-70"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBookmark(post._id);
                  }}
                  data-tooltip-id="bookmark"
                  data-tooltip-content="Bookmark"
                >
                  {post.bookmarked ? (
                    <FaBookmark className="mr-1 inline text-light" />
                  ) : (
                    <FaRegBookmark className="mr-1 inline" />
                  )}
                </div>
                <Tooltip
                  id="bookmark"
                  noArrow
                  place="bottom"
                  delayShow={1000}
                  style={{
                    backgroundColor: "#d6d6d6",
                    color: "#171616",
                    padding: "3px 6px",
                    fontSize: "13px",
                    position: "absolute",
                    fontFamily: "inherit",
                    zIndex: "1",
                  }}
                />
              </div>
            </div>
            <h2 className="text-base font-medium text-title">{post.title}</h2>
            <p className="mt-2 line-clamp-2 break-before-all text-sm tracking-wide text-tertiary">
              {post.body}
            </p>
            <div className="flex items-center justify-between gap-x-3 py-2 text-sm">
              <div className="flex items-center gap-x-3">
                <div
                  className="my-2 flex h-fit w-16 items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike(post._id);
                  }}
                  data-tooltip-id="like"
                  data-tooltip-content="Like"
                >
                  {post.liked ? (
                    <FaHeart className="mr-1 inline  text-light" />
                  ) : (
                    <FaRegHeart className="mr-1 inline" />
                  )}{" "}
                  {post.likes}
                </div>
                <Tooltip
                  id="like"
                  noArrow
                  place="bottom"
                  delayShow={1000}
                  style={{
                    backgroundColor: "#d6d6d6",
                    color: "#171616",
                    padding: "3px 6px",
                    fontSize: "13px",
                    position: "absolute",
                    fontFamily: "inherit",
                    zIndex: "1",
                  }}
                />
                <div
                  className="flex h-fit w-16 items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70"
                  data-tooltip-id="comment"
                  data-tooltip-content="Comment"
                >
                  <FaRegComment className="mr-1 inline" /> {post.commentCount}
                </div>
                <Tooltip
                  id="comment"
                  noArrow
                  place="bottom"
                  delayShow={1000}
                  style={{
                    backgroundColor: "#d6d6d6",
                    color: "#171616",
                    padding: "3px 6px",
                    fontSize: "13px",
                    position: "absolute",
                    fontFamily: "inherit",
                    zIndex: "1",
                  }}
                />
              </div>
              <div className="flex items-center gap-x-3">
                <div
                  className="flex h-fit w-[70px] items-center justify-between rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70"
                  data-tooltip-id="view"
                  data-tooltip-content="View"
                >
                  <FaRegEye className="mr-1 inline" /> {post.views}
                </div>{" "}
                <Tooltip
                  id="view"
                  noArrow
                  place="bottom"
                  delayShow={1000}
                  style={{
                    backgroundColor: "#d6d6d6",
                    color: "#171616",
                    padding: "3px 6px",
                    fontSize: "13px",
                    position: "absolute",
                    fontFamily: "inherit",
                    zIndex: "1",
                  }}
                />
                <div className="relative h-fit w-14 rounded-full border border-primary px-3 text-tertiary">
                  <div
                    className="flex cursor-pointer items-center justify-center py-3 text-left"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShareClick(post._id);
                    }}
                    data-tooltip-id="share"
                    data-tooltip-content="Share"
                  >
                    <FaShareAlt className="inline" />
                  </div>{" "}
                  <Tooltip
                    id="share"
                    noArrow
                    place="bottom"
                    delayShow={1000}
                    style={{
                      backgroundColor: "#d6d6d6",
                      color: "#171616",
                      padding: "3px 6px",
                      fontSize: "13px",
                      position: "absolute",
                      fontFamily: "inherit",
                      zIndex: "1",
                    }}
                  />
                  {shareDropDownOpen === post._id && (
                    <div
                      ref={shareDropdownRef}
                      className="absolute right-0 top-0 w-40 origin-top-right rounded-md bg-secondary shadow-lg"
                    >
                      <div
                        className="flex cursor-pointer items-center gap-x-2 rounded-md px-4 py-3 text-tertiary hover:opacity-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyLink(post._id);
                        }}
                      >
                        <FaLink />
                        Copy Link
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      )}{" "}
      <div className=" flex w-full items-center justify-center">
        <div ref={loadMoreRef} />
        {isLoadingMore && (
          <div className="mx-auto block rounded px-4 py-1 text-light">
            <p>Loading more...</p>
          </div>
        )}
        {posts.length > 0 && hasMore && !isLoadingMore && (
          <button
            onClick={handleLoadMore}
            className="mx-auto block rounded bg-primary px-4 py-1  text-light"
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default Feed;
