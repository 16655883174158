import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { clearToken } from "../store/authSlice";
import {
  FaFeatherAlt,
  FaHandsHelping,
  FaHistory,
  FaHome,
  FaMoneyBill,
  FaUserCircle,
} from "react-icons/fa";

interface ProfileMenuModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const { REACT_APP_S3_URL } = process.env;

const ProfileMenuModal: React.FC<ProfileMenuModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const username = useSelector((state: RootState) => state.auth.username);
  const handleLogout = () => {
    dispatch(clearToken());
    onClose();
    navigate("/");
  };

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="absolute bottom-0 w-full rounded-t-2xl bg-secondary px-6 py-4 text-left text-light"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mt-1 flex flex-col items-center justify-start text-left">
          <div
            className="mb-2 flex w-full items-center justify-start gap-x-2 text-left"
            onClick={() => handleNavigate("/profile")}
          >
            <div>
              <FaUserCircle className="h-10 w-10" />
            </div>
            <div className="">
              <h1>View Profile</h1>
              <h1 className="text-sm opacity-80">{username}</h1>
            </div>
          </div>
          <div
            className="mt-2 flex w-full items-center justify-start gap-x-4 rounded-lg py-2 text-left"
            onClick={() => handleNavigate("/home")}
          >
            <FaHome className="h-6 w-6" />
            <button>Home</button>
          </div>
          <div
            className="mt-2 flex w-full items-center justify-start gap-x-4 rounded-lg py-2 text-left"
            onClick={() => handleNavigate("/profile", { activeTab: "myPosts" })}
          >
            <FaFeatherAlt className="h-5 w-5" />
            <button>My Posts</button>
          </div>
          <div
            className="mt-2 flex w-full items-center justify-start gap-x-4 rounded-lg py-2 text-left"
            onClick={() =>
              handleNavigate("/profile", { activeTab: "purchases" })
            }
          >
            <FaMoneyBill className="h-5 w-5" />
            <button>Purchases</button>
          </div>
          <div
            className="mt-2 flex w-full items-center justify-start gap-x-4 rounded-lg py-2 text-left"
            onClick={() =>
              handleNavigate("/profile", { activeTab: "sessions" })
            }
          >
            <FaHandsHelping className="h-5 w-5" />
            <button>Sessions</button>
          </div>
          <div
            className="mt-2 flex w-full items-center justify-start gap-x-4 rounded-lg py-2 text-left"
            onClick={() => handleNavigate("/payments-history")}
          >
            <FaHistory className="h-5 w-5" />
            <button>Payment History</button>
          </div>
          <div className="my-2 w-full border-t border-[#d6d6d634]"></div>
          <div
            className="mt-1 flex w-full items-center justify-start gap-x-4 rounded-lg py-1 text-left"
            onClick={handleLogout}
          >
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/logout.png`}
              className="h-5"
              alt="logout"
            />
            <button>Logout</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMenuModal;
