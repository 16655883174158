import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BottomModal from "./BottomModal";
import ProfileMenuModal from "./ProfileMenuModal";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import SideMenu from "./SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

const { REACT_APP_S3_URL } = process.env;

const SpaceHeader = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isTagContainerOpen, setIsTagContainerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const username = useSelector((state: RootState) => state.auth.username);

  const tags = [
    "confession",
    "query",
    "question",
    "distress",
    "self help",
    "daily challenge",
  ];

  const handleLogin = () => {
    navigate("/login");
  };

  const handleTagClick = (tag: string) => {
    setSearchValue((prev) => {
      const params = new URLSearchParams(window.location.search);
      let existingTags = params.getAll("tags");
      existingTags = existingTags.map((t) => t.replace(/-/g, " "));
      const tagIndex = existingTags.indexOf(tag);
      if (tagIndex !== -1) {
        existingTags.splice(tagIndex, 1);
      } else {
        existingTags.push(tag);
      }
      const formattedTags = existingTags.map((t) => t.replace(/\s+/g, "-"));
      const newTags = formattedTags.map((t) => `tags=${t}`).join("&");
      navigate(`/space/?${newTags}`);
      return existingTags.join(", ");
    });
    setIsTagContainerOpen(false);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setIsTagContainerOpen(true);
    navigate("/space");
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setIsSearchOpen(false);
    setIsTagContainerOpen(false);
    navigate("/space");
  };

  const handleComposePost = () => {
    if (!username) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    } else {
      navigate("/compose");
    }
  };

  return (
    <>
      <div className="sticky top-0 z-50 flex items-center justify-between border-b border-primary bg-secondary px-6 py-2 text-light">
        <div className="flex items-center gap-x-2">
          <img
            src={`${REACT_APP_S3_URL}/assets/icons/menu-bar.png`}
            className="h-4"
            alt="menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
          <img
            src={`${REACT_APP_S3_URL}/assets/one-life-logo-white.png`}
            className="h-11 opacity-80"
            alt="one-life-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="flex items-center gap-x-4">
          <img
            src={`${REACT_APP_S3_URL}/assets/icons/search-interface-symbol.png`}
            className="h-5 cursor-pointer"
            alt="search-interface-symbol"
            onClick={() => {
              setIsSearchOpen(true);
              setIsTagContainerOpen(true);
            }}
          />
          <img
            src={`${REACT_APP_S3_URL}/assets/icons/plus.png`}
            className="h-5 cursor-pointer"
            alt="plus"
            onClick={handleComposePost}
          />
          {username ? (
            <FaUserCircle
              className="h-8 w-8 cursor-pointer"
              onClick={() => setIsProfileMenuOpen(true)}
            />
          ) : (
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/more.png`}
              className="h-6 cursor-pointer"
              alt="more"
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>
      </div>

      {isSearchOpen && (
        <div className="sticky top-0 z-50 flex items-center justify-between border-b border-primary bg-secondary px-4 py-2.5 text-light">
          <div className="relative flex w-full items-center">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search..."
              className="w-full rounded-lg border border-primary bg-secondary p-2 pl-10 text-base text-light placeholder-primary"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 top-1/2 -translate-y-1/2 transform text-[#505050]"
            />
            {searchValue && (
              <button
                onClick={handleClearSearch}
                className="absolute right-3 top-1/2 -translate-y-1/2 transform text-[#505050]"
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            )}
          </div>
          <button
            onClick={handleCancelSearch}
            className="ml-2 text-[15px] text-light"
          >
            Cancel
          </button>
        </div>
      )}

      {isTagContainerOpen && (
        <div className="absolute left-0 right-0 z-50 h-auto min-h-screen w-auto rounded-lg border-none bg-secondary shadow-lg">
          <ul className="flex flex-wrap items-center justify-start gap-3 px-3 py-6">
            {tags.map((tag, index) => (
              <li
                key={index}
                className={`cursor-pointer rounded-full border border-primary px-4 py-2 text-left text-base text-light hover:bg-[#5c5c5cc2] ${
                  searchValue.includes(tag) ||
                  (tag === "self help" && searchValue.includes("self-help")) ||
                  (tag === "daily challenge" &&
                    searchValue.includes("daily-challenge"))
                    ? "bg-[#404040c2]"
                    : "bg-[#40404038]"
                }`}
                onClick={() => handleTagClick(tag)}
              >
                {tag}
              </li>
            ))}
          </ul>
        </div>
      )}

      <BottomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onLogin={handleLogin}
      />
      <ProfileMenuModal
        isOpen={isProfileMenuOpen}
        onClose={() => setIsProfileMenuOpen(false)}
      />
      <SideMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};

export default SpaceHeader;
