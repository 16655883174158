import React, { useState, useRef, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import toast from "react-hot-toast";
import LoadingScreen from "./LoadingScreen";
import { useMediaQuery } from "react-responsive";
import { fetchPost, createPost, updatePost } from "../api/postsAPI";

const ComposePost = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const location = useLocation();
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const queryParams = new URLSearchParams(location.search);
  const postId = queryParams.get("postId");
  const [isLoading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const tags = [
    "Confession",
    "Question",
    "Self Help",
    "Query",
    "Distress",
    "Daily Challenge",
  ];

  const token = useSelector((state: RootState) => state.auth?.token || null);
  const isXLScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  useEffect(() => {
    if (isXLScreen) {
      navigate("/");
    }
  }, [isXLScreen, navigate]);

  useEffect(() => {
    if (postId) {
      fetchPostDetail();
    } else {
      setLoading(false);
    }
  }, [postId]);

  const fetchPostDetail = async () => {
    if (!token || !postId) {
      toast.error("Token or post ID not found");
      setLoading(false);
      return;
    }
    try {
      const data = await fetchPost(token, postId);
      if (data && data.success) {
        const post = data.data;
        setTitle(post.title);
        setBody(post.body);
        const fetchedTags = post.tags.map((tag: string) =>
          tag
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        );
        setSelectedTags(fetchedTags);
      } else {
        toast.error("Failed to fetch post data");
      }
    } catch (error) {
      toast.error("Failed to fetch post");
    } finally {
      setLoading(false);
    }
  };

  const handleTagClick = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
    );
  };

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.target.value);
    if (bodyRef.current) {
      bodyRef.current.style.height = "auto";
      bodyRef.current.style.height = `${bodyRef.current.scrollHeight}px`;
    }
  };

  const handleSavePost = async () => {
    if (!token || isSubmitting) {
      return;
    }

    setSubmitting(true);

    const postData = {
      title,
      body,
      tags: selectedTags.map((tag) =>
        tag.trim().toLowerCase().replace(" ", "-"),
      ),
    };

    try {
      let response;
      if (postId) {
        response = await updatePost(token, postId, postData);
        toast.success("Post updated");
        navigate(`/space/post/${postId}`);
      } else {
        response = await createPost(token, postData);
        toast.success("Post added");
        const newPostId = response.data._id;
        navigate(`/space/post/${newPostId}`);
      }
    } catch (error) {
      console.error("Error saving post:", error);
      toast.error("Failed to save post. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div className="h-auto min-h-screen bg-primary text-light">
      <div className="sticky top-14 z-10 rounded-xl bg-primary">
        <div className="flex items-center justify-between px-7 pb-5 pt-5">
          <FontAwesomeIcon
            icon={faTimes}
            className="h-6 w-6 cursor-pointer border-none hover:opacity-70"
            onClick={() => navigate("/")}
          />
          <button
            className={`rounded-full border border-primary bg-light px-4 py-1.5 text-base text-primary hover:bg-opacity-70 xl:px-6 xl:py-2 xl:text-base ${
              isSubmitting ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={!isSubmitting ? handleSavePost : undefined}
            disabled={isSubmitting}
          >
            {postId ? "Update" : "Create"}
          </button>
        </div>
      </div>
      <div className="px-7">
        <div className="mb-4">
          <div className="mb-6">
            <p className="mb-2 text-base font-medium text-light xl:text-base">
              Tags:
            </p>
            <div className="flex flex-wrap gap-2">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className={`cursor-pointer rounded-full border border-primary px-3 py-1.5 text-center text-[15px] xl:px-4 xl:py-2 ${
                    selectedTags.includes(tag)
                      ? "bg-white-light text-black"
                      : "bg-dark text-[#fcfcfc] hover:bg-[#eee8e12a]"
                  }`}
                  onClick={() => !isSubmitting && handleTagClick(tag)} // Prevent click when submitting
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="mb-5">
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full rounded-md border-none bg-primary text-xl font-semibold text-light placeholder-primary outline-none"
            disabled={isSubmitting}
          />
        </div>
        <div className="mb-4">
          <textarea
            ref={bodyRef}
            rows={1}
            placeholder="Body"
            value={body}
            onChange={handleBodyChange}
            className="w-full resize-none rounded-md border-none bg-primary text-base text-light placeholder-primary outline-none"
            style={{ overflow: "hidden" }}
            disabled={isSubmitting}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default ComposePost;
