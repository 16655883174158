import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Feed from "../components/Feed";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import TherapistSidebar from "../components/TherapistSidebar";
import PostDetail from "../components/PostDetail";
import useTokenValidation from "../hooks/useTokenValidation";
import UserProfile from "../components/UserProfile";
import LoadingScreen from "../components/LoadingScreen";
import { Toaster } from "react-hot-toast";
import SpaceHeader from "../components/SpaceHeader";
import ScrollToTop from "../components/ScrollToTop";
import ComposePost from "../components/ComposePost";
import { useMediaQuery } from "react-responsive";
import ChatBot from "../components/ChatBot";

const SpeakItOutPage: React.FC = () => {
  useTokenValidation();

  const [loading, setLoading] = useState(true);
  const isXLScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isXLScreen && location.pathname === "/consultation") {
      navigate("/");
    }
  }, [isXLScreen, location.pathname, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-primary font-community xl:h-screen">
      <div className="m-auto hidden h-svh w-[90%] flex-row overflow-hidden xl:flex">
        <Sidebar />
        <div className="w-[80%]">
          <Header />
          <div className="flex">
            <Routes>
              <Route path="/space" element={<Feed />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/space/post/:postId" element={<PostDetail />} />
            </Routes>
            <TherapistSidebar />
          </div>
        </div>
      </div>
      <div className="block h-auto xl:hidden">
        <SpaceHeader />
        <ScrollToTop />
        <div className="py-5">
          <Routes>
            <Route path="/space" element={<Feed />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/space/post/:postId" element={<PostDetail />} />
            <Route path="/consultation" element={<TherapistSidebar />} />
            <Route path="/compose" element={<ComposePost />} />
          </Routes>
        </div>
      </div>
      <ChatBot />
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #404040c2",
            color: "#d6d6d6",
            backgroundColor: "#1a1a1a",
            animation: "forwards",
            fontSize: "14px",
          },
          success: {
            iconTheme: {
              primary: "#d6d6d6",
              secondary: "black",
            },
          },
          error: {
            iconTheme: {
              primary: "#d6d6d6",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default SpeakItOutPage;
