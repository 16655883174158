import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faClose,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaRegBookmark,
  FaBookmark,
  FaRegComment,
  FaHeart,
  FaRegHeart,
  FaReply,
  FaUserCircle,
  FaLink,
  FaShareAlt,
  FaRegEye,
  FaEdit,
  FaTrashAlt,
} from "react-icons/fa";
import SkeletonLoader from "./SkeletonLoader";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import CreatePost from "./CreatePost";
import "../styles/CreatePost.css";
import { formatRelativeTime } from "../utils/dateUtils";
import { Tooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";
interface Comment {
  _id: string;
  postId: string;
  comment: string;
  userId: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  replies: Comment[];
  __v: number;
}

interface Post {
  _id: string;
  username: string;
  title: string;
  body: string;
  userId: string;
  likes: number;
  views: number;
  commentCount: number;
  status: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  comments: Comment[];
  liked: boolean;
  bookmarked: boolean;
}

const { REACT_APP_API_URL, REACT_APP_URL } = process.env;

const PostDetail: React.FC = () => {
  const location = useLocation() as {
    state?: { fromFeed: boolean; index: number };
  };
  const { postId } = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const profileId = useSelector((state: RootState) => state.auth.profile?._id);
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [newComment, setNewComment] = useState<string>("");
  const [showCommentOptions, setShowCommentOptions] = useState(false);
  const [showReplyOptions, setShowReplyOptions] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editCommentText, setEditCommentText] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [postToEdit, setPostToEdit] = useState<Post | null>(null);
  const [replyText, setReplyText] = useState<string>("");
  const [replyingToCommentId, setReplyingToCommentId] = useState<string | null>(
    null,
  );
  const [shareDropDownOpen, setShareDropDownOpen] = useState<string | null>(
    null,
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const shareDropdownRef = useRef<HTMLDivElement>(null);

  const isXLScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  const handleEditPost = (post: Post) => {
    if (isXLScreen) {
      setPostToEdit(post);
      setShowCreatePost(true);
    } else {
      navigate(`/compose/?postId=${post._id}`);
    }
  };

  const fetchPostDetail = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/post/${postId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.success) {
        setPost(response.data.data);
      } else {
        setError("Failed to fetch post data");
      }
    } catch (error) {
      setError("Failed to fetch post");
    } finally {
      setLoading(false);
    }
  };

  const handlePostCreated = (postId: string) => {
    setShowCreatePost(false);
    navigate(`/space/post/${postId}`);
  };

  const handlePostUpdated = () => {
    fetchPostDetail();
    setShowCreatePost(false);
    setDropdownVisible({});
  };

  useEffect(() => {
    fetchPostDetail();
  }, [postId, token]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareDropdownRef.current &&
        !shareDropdownRef.current.contains(event.target as Node)
      ) {
        setShareDropDownOpen(null);
      }
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shareDropDownOpen]);

  const handleShareClick = (postId: string) => {
    if (shareDropDownOpen === postId) {
      setShareDropDownOpen(null);
    } else {
      setShareDropDownOpen(postId);
    }
  };

  const handleCopyLink = (postId: string) => {
    if (post) {
      navigator.clipboard
        .writeText(`${REACT_APP_URL}/space/post/${postId}`)
        .then(() => {
          toast.success("Link copied to clipboard!");
          setShareDropDownOpen(null);
        })
        .catch((error) => {
          console.error("Failed to copy link:", error);
          toast.error("Failed to copy link. Please try again.");
        });
    } else {
      toast.error("Post not found.");
    }
  };

  const handleAddComment = async () => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    if (!newComment.trim() || !postId) return;

    try {
      await axios.post(
        `${REACT_APP_API_URL}/post/comment/${postId}`,
        { comment: newComment },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setNewComment("");
      toast.success("Comment added");
      fetchPostDetail(); // Re-fetch the post details to update the comments
      setShowCommentOptions(false);
    } catch (error) {
      toast.error("Failed to add comment. Please try again later.");
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    if (!token || !postId) return;

    try {
      await axios.delete(
        `${REACT_APP_API_URL}/post/comment/${postId}/${commentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success("Comment deleted");
      fetchPostDetail(); // Re-fetch the post details to update the comments
    } catch (error) {
      toast.error("Failed to delete comment. Please try again later.");
    }
  };

  const handleEditComment = async () => {
    if (!token || !postId || !editingCommentId || !editCommentText.trim())
      return;

    try {
      await axios.patch(
        `${REACT_APP_API_URL}/post/comment/${postId}/${editingCommentId}`,
        { comment: editCommentText },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success("Comment updated");
      setEditingCommentId(null);
      setEditCommentText("");
      fetchPostDetail(); // Re-fetch the post details to update the comments
    } catch (error) {
      toast.error("Failed to update comment. Please try again later.");
    }
  };

  const handleLike = async () => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }

    try {
      setPost((prevPost) => {
        if (!prevPost) return null;
        return {
          ...prevPost,
          liked: !prevPost.liked,
          likes: prevPost.liked ? prevPost.likes - 1 : prevPost.likes + 1,
        };
      });
      await axios.post(
        `${REACT_APP_API_URL}/post/like/${postId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      toast.error("Failed to like the post. Please try again later.");
      setPost((prevPost) => {
        if (!prevPost) return null;
        return {
          ...prevPost,
          liked: !prevPost.liked,
          likes: prevPost.liked ? prevPost.likes - 1 : prevPost.likes + 1,
        };
      });
    }
  };

  const handleBookmark = async () => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }

    try {
      setPost((prevPost) => {
        if (!prevPost) return null;
        const newBookmarkedStatus = !prevPost.bookmarked;
        toast.success(
          newBookmarkedStatus
            ? "Added to your Bookmarks"
            : "Removed from your Bookmarks",
        );
        return {
          ...prevPost,
          bookmarked: newBookmarkedStatus,
        };
      });

      await axios.post(
        `${REACT_APP_API_URL}/post/bookmark/${postId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      toast.error("Failed to bookmark the post. Please try again later.");
      setPost((prevPost) => {
        if (!prevPost) return null;
        const newBookmarkedStatus = !prevPost.bookmarked;
        return {
          ...prevPost,
          bookmarked: newBookmarkedStatus,
        };
      });
    }
  };

  const handleDeletePost = async (postId: string) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }

    try {
      await axios.delete(`${REACT_APP_API_URL}/post/${postId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Post deleted");
      navigate("/space");
    } catch (error) {
      toast.error("Failed to delete post. Please try again later.");
    }
  };

  const handleAddReply = async () => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    if (!replyText.trim() || !postId || !replyingToCommentId) return;

    try {
      await axios.post(
        `${REACT_APP_API_URL}/post/comment/reply/${postId}/${replyingToCommentId}`,
        { comment: replyText },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setReplyText("");
      setReplyingToCommentId(null);
      toast.success("Comment added");
      fetchPostDetail();
    } catch (error) {
      toast.error("Failed to add comment. Please try again later.");
    }
  };

  const toggleDropdown = (id: string) => {
    setDropdownVisible((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleCommentCancel = () => {
    setShowCommentOptions(false);
    setNewComment("");
  };

  const handleReplyCancel = () => {
    setShowReplyOptions(false);
    setReplyText("");
    setReplyingToCommentId(null);
  };

  const handleBackClick = () => {
    if (location.state?.fromFeed) {
      navigate("/space", { state: { index: location.state.index } });
    } else {
      navigate("/space");
    }
  };

  if (loading) {
    return (
      <div className="m-auto h-auto w-[96%] rounded-xl border border-primary drop-shadow xl:mx-5 xl:h-screen xl:w-[60%]">
        <div className="sticky top-0 z-10 rounded-xl bg-primary">
          <div className="flex items-center justify-start text-white">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className="cursor-pointer py-5 pl-5 pr-3 hover:opacity-70"
              onClick={handleBackClick}
            />
            <h2 className="text-xl font-bold tracking-wide">Post</h2>
          </div>{" "}
          <div className="border-t border-primary"></div>
        </div>
        <SkeletonLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="m-auto h-auto w-[96%] rounded-lg font-community drop-shadow xl:mx-5 xl:h-screen xl:w-[60%]">
        {error}
      </div>
    );
  }

  if (!post) {
    return null;
  }

  return (
    <div className="hide-scrollbar m-auto h-auto min-h-screen w-[96%] rounded-xl border border-primary font-community shadow-md xl:mx-5 xl:h-screen xl:w-[60%] xl:overflow-y-scroll xl:pb-28">
      <div className="sticky top-0 z-10 rounded-xl bg-primary">
        <div className="flex items-center justify-start text-white ">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className="cursor-pointer border-none py-5 pl-5 pr-3 hover:opacity-70"
            onClick={handleBackClick}
          />
          <h2 className="text-xl font-bold tracking-wide">Post</h2>{" "}
        </div>{" "}
        <div className="border-t border-primary"></div>
      </div>
      <div className="rounded-base mb-4 bg-primary px-5 py-5">
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center">
            <FaUserCircle className="mr-2 h-8 w-8 text-light" />
            <span className="text-base font-semibold text-light">
              {post.username ? post.username : "Anonymous"}
            </span>
          </div>
          <div className="flex items-center">
            <span className="mr-2 text-[13px] text-tertiary">
              {formatRelativeTime(post.createdAt)}
            </span>
            <div
              className="cursor-pointer text-tertiary hover:scale-105 hover:opacity-70"
              onClick={handleBookmark}
              data-tooltip-id="bookmark"
              data-tooltip-content="Bookmark"
            >
              {post.bookmarked ? (
                <FaBookmark className="mr-1 inline text-light" />
              ) : (
                <FaRegBookmark className="mr-1 inline" />
              )}
            </div>
            <Tooltip
              id="bookmark"
              noArrow
              delayShow={1000}
              place="bottom"
              style={{
                backgroundColor: "#d6d6d6",
                color: "#171616",
                padding: "3px 6px",
                fontSize: "13px",
                position: "absolute",
                fontFamily: "inherit",
                zIndex: "1",
              }}
            />
            {post.userId === profileId && (
              <div className="relative ml-2">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="cursor-pointer text-tertiary"
                  data-tooltip-id="more"
                  data-tooltip-content="more"
                  onClick={() => toggleDropdown(post._id)}
                />{" "}
                <Tooltip
                  id="more"
                  noArrow
                  delayShow={1000}
                  place="bottom"
                  style={{
                    backgroundColor: "#d6d6d6",
                    color: "#171616",
                    padding: "3px 6px",
                    fontSize: "13px",
                    position: "absolute",
                    fontFamily: "inherit",
                    zIndex: "1",
                  }}
                />
                {dropdownVisible[post._id] && (
                  <div
                    className="absolute -top-1 right-0 mt-2 w-40 origin-top-right rounded-md bg-secondary text-sm shadow-lg"
                    ref={dropdownRef}
                  >
                    <button
                      className="flex w-full items-center gap-x-2 px-4 py-2 text-left text-tertiary hover:opacity-70"
                      onClick={() => handleEditPost(post)}
                    >
                      <FaEdit />
                      Edit
                    </button>
                    <button
                      className="flex w-full items-center gap-x-2 border-t border-[#999999] border-opacity-20 px-4 py-2 text-left text-[#ac3737] hover:opacity-70"
                      onClick={() => setShowConfirmDialog(true)}
                    >
                      <FaTrashAlt />
                      Delete
                    </button>
                  </div>
                )}
              </div>
            )}
            {showConfirmDialog && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-[80%] max-w-md rounded-lg bg-secondary p-6 xl:w-full">
                  <h2 className="mb-4 text-base font-semibold text-light">
                    Are you sure?
                  </h2>
                  <p className="mb-4 text-sm text-tertiary">
                    Are you sure you want to delete this post? It cannot be
                    recovered.
                  </p>
                  <div className="flex justify-end space-x-3 text-base xl:text-sm ">
                    <button
                      className="rounded-full border border-primary px-6 py-2 text-sm text-light hover:bg-opacity-70 xl:text-base"
                      onClick={() => setShowConfirmDialog(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="rounded-full border border-primary bg-light px-6 py-2 text-sm text-black hover:bg-opacity-70 xl:text-base"
                      onClick={() => handleDeletePost(post._id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <h2 className="mt-5 text-[20px] font-semibold tracking-wide text-title">
          {post.title}
        </h2>
        <p className="mt-2 text-base text-tertiary">{post.body}</p>
        <div className="flex items-center justify-between gap-x-3 py-2 text-sm">
          <div className="flex items-center gap-x-3">
            <div
              className="my-2 flex h-fit w-16 cursor-pointer items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70"
              data-tooltip-id="like"
              data-tooltip-content="Like"
              onClick={handleLike}
            >
              {post.liked ? (
                <FaHeart className="mr-1 inline  text-light" />
              ) : (
                <FaRegHeart className="mr-1 inline" />
              )}{" "}
              {post.likes}
            </div>
            <Tooltip
              id="like"
              noArrow
              place="bottom"
              delayShow={1000}
              style={{
                backgroundColor: "#d6d6d6",
                color: "#171616",
                padding: "3px 6px",
                fontSize: "13px",
                position: "absolute",
                fontFamily: "inherit",
                zIndex: "1",
              }}
            />
            <div
              className="flex h-fit w-16 items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary"
              data-tooltip-id="comment"
              data-tooltip-content="Comment"
            >
              <FaRegComment className="mr-1 inline" /> {post.commentCount}
            </div>
            <Tooltip
              id="comment"
              noArrow
              place="bottom"
              delayShow={1000}
              style={{
                backgroundColor: "#d6d6d6",
                color: "#171616",
                padding: "3px 6px",
                fontSize: "13px",
                position: "absolute",
                fontFamily: "inherit",
                zIndex: "1",
              }}
            />
          </div>
          <div className="flex items-center gap-x-3">
            <div
              className="flex h-fit w-[70px] items-center justify-between rounded-full border border-primary px-3 py-2 text-tertiary"
              data-tooltip-id="view"
              data-tooltip-content="View"
            >
              <FaRegEye className="mr-1 inline" /> {post.views}
            </div>
            <Tooltip
              id="view"
              noArrow
              place="bottom"
              delayShow={1000}
              style={{
                backgroundColor: "#d6d6d6",
                color: "#171616",
                padding: "3px 6px",
                fontSize: "13px",
                position: "absolute",
                fontFamily: "inherit",
                zIndex: "1",
              }}
            />
            <div className="relative h-fit w-14 rounded-full border border-primary px-3 text-tertiary">
              <div
                className="flex cursor-pointer items-center justify-center py-3 text-left hover:scale-105 hover:opacity-70"
                data-tooltip-id="share"
                data-tooltip-content="Share"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(post._id);
                }}
              >
                <FaShareAlt className="inline" />
              </div>{" "}
              <Tooltip
                id="share"
                noArrow
                place="bottom"
                delayShow={1000}
                style={{
                  backgroundColor: "#d6d6d6",
                  color: "#171616",
                  padding: "3px 6px",
                  fontSize: "13px",
                  position: "absolute",
                  fontFamily: "inherit",
                  zIndex: "1",
                }}
              />
              {shareDropDownOpen === post._id && (
                <div
                  ref={shareDropdownRef}
                  className="absolute right-0 top-0 w-40 origin-top-right rounded-md bg-secondary shadow-lg"
                >
                  <div
                    className="flex cursor-pointer items-center gap-x-2 rounded-md px-4 py-3 text-tertiary hover:opacity-90"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyLink(post._id);
                    }}
                  >
                    <FaLink />
                    Copy Link
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border-t border-primary"></div>
        <div className="mt-4 rounded-xl bg-secondary">
          <textarea
            className="hide-scrollbar h-20 w-full rounded-xl border-none bg-secondary p-3 text-base text-[#b6b6b6] placeholder-primary outline-none xl:text-[15px] "
            rows={2}
            placeholder="Enter your comment"
            value={newComment}
            onClick={() => setShowCommentOptions(true)}
            onChange={(e) => setNewComment(e.target.value)}
          ></textarea>
          {showCommentOptions && (
            <div className="comment-options flex w-full items-center justify-end gap-x-3 rounded-xl bg-secondary px-3 pb-3 text-base xl:text-sm  ">
              <div>
                <button
                  className="w-28 rounded-full border border-primary p-2 text-[#b6b6b6] xl:w-24"
                  onClick={handleCommentCancel}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="w-28 rounded-full border border-primary bg-[#b6b6b6] p-2 text-black xl:w-24"
                  onClick={handleAddComment}
                >
                  Comment
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold text-title">Comments</h3>
          {post.comments && post.comments.length > 0 ? (
            post.comments.map((comment) => (
              <div
                key={comment._id}
                className="mt-4 rounded-lg bg-secondary p-3"
              >
                <div className="">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <FaUserCircle className="mr-2 h-8 w-8 text-light xl:h-6 xl:w-6" />
                      <p className="text-base font-semibold text-light">
                        {comment.username}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <span className="text-[13px] text-tertiary">
                        {formatRelativeTime(comment.createdAt)}
                      </span>
                      {comment.userId === profileId && (
                        <div className="relative ml-2">
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            className="cursor-pointer text-tertiary"
                            onClick={() => toggleDropdown(comment._id)}
                          />
                          {dropdownVisible[comment._id] && (
                            <div
                              className="absolute -top-1 right-0 mt-2 w-40 origin-top-right rounded-md bg-secondary text-sm shadow-lg"
                              ref={dropdownRef}
                            >
                              <button
                                className="flex w-full items-center gap-x-2 px-4 py-2 text-left text-tertiary hover:opacity-70"
                                onClick={() => {
                                  setEditCommentText(comment.comment);
                                  setEditingCommentId(comment._id);
                                  setDropdownVisible((prev) => ({
                                    ...prev,
                                    [comment._id]: false,
                                  }));
                                }}
                              >
                                <FaEdit />
                                Edit
                              </button>
                              <button
                                className="flex w-full items-center gap-x-2 border-t border-[#999999] border-opacity-20 px-4 py-2 text-left text-[#ac3737] hover:opacity-70"
                                onClick={() => handleDeleteComment(comment._id)}
                              >
                                <FaTrashAlt />
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {editingCommentId === comment._id ? (
                    <div className="flex justify-end">
                      <div className="mt-4 w-[98%] rounded-xl bg-primary">
                        <textarea
                          className="hide-scrollbar h-20 w-full rounded-xl border-none bg-primary p-3 text-base text-[#b6b6b6] placeholder-primary outline-none xl:text-[15px]"
                          rows={2}
                          onClick={() => setShowReplyOptions(true)}
                          value={editCommentText}
                          onChange={(e) => setEditCommentText(e.target.value)}
                        ></textarea>

                        <div className="comment-options flex w-full items-center justify-end gap-x-3 rounded-xl bg-primary px-3 pb-3 text-base xl:text-sm  ">
                          <div>
                            <button
                              className="w-24 rounded-full border border-primary p-2 text-[#b6b6b6]"
                              onClick={() => {
                                setEditingCommentId(null);
                                setEditCommentText("");
                                setShowReplyOptions(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div>
                            <button
                              className="w-24 rounded-full border border-primary bg-[#b6b6b6] p-2 text-black"
                              onClick={handleEditComment}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="my-1 ml-10 h-auto break-before-all text-wrap text-base text-tertiary xl:ml-8 xl:text-sm">
                      {comment.comment}
                    </p>
                  )}
                </div>
                <div className="ml-10 mt-2 flex items-center justify-start gap-x-3 text-sm text-gray-500 xl:ml-8">
                  <FaReply className="text-[#f0f0f0]" />
                  <button
                    className="text-[#f0f0f0] hover:text-tertiary hover:underline"
                    onClick={() => setReplyingToCommentId(comment._id)}
                  >
                    Reply
                  </button>
                </div>
                {replyingToCommentId === comment._id && (
                  <div className="flex justify-end">
                    <div className="mt-4 w-[98%] rounded-xl bg-primary">
                      <textarea
                        className="hide-scrollbar h-20 w-full rounded-xl border-none bg-primary p-3 text-base text-[#b6b6b6] placeholder-primary outline-none xl:text-[15px]"
                        rows={2}
                        placeholder="Enter your reply"
                        onClick={() => setShowReplyOptions(true)}
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                      ></textarea>
                      {showReplyOptions && (
                        <div className="comment-options flex w-full items-center justify-end gap-x-3 rounded-xl bg-primary px-3 pb-3 text-base xl:text-sm  ">
                          <div>
                            <button
                              className="w-24 rounded-full border border-primary p-2 text-[#b6b6b6]"
                              onClick={handleReplyCancel}
                            >
                              Cancel
                            </button>
                          </div>
                          <div>
                            <button
                              className="w-24 rounded-full border border-primary bg-[#b6b6b6] p-2 text-black"
                              onClick={handleAddReply}
                            >
                              Reply
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {comment.replies && comment.replies.length > 0 && (
                  <div className="ml-4 mt-4">
                    {comment.replies.map((reply) => (
                      <div
                        key={reply._id}
                        className="mt-2 rounded-lg bg-[#1f1f1f] p-3"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FaUserCircle className="mr-2 h-8 w-8 text-light xl:h-6 xl:w-6" />
                            <p className="text-base font-semibold text-light">
                              {reply.username}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <span className="text-[13px] text-tertiary">
                              {formatRelativeTime(reply.createdAt)}
                            </span>
                            {reply.userId === profileId && (
                              <div className="relative ml-2">
                                <FontAwesomeIcon
                                  icon={faEllipsisV}
                                  className="cursor-pointer text-tertiary"
                                  onClick={() => toggleDropdown(reply._id)}
                                />
                                {dropdownVisible[reply._id] && (
                                  <div
                                    className="absolute -top-1 right-0 mt-2 w-40 origin-top-right rounded-md bg-secondary text-sm shadow-lg"
                                    ref={dropdownRef}
                                  >
                                    <button
                                      className="flex w-full items-center gap-x-2 px-4 py-2 text-left text-tertiary hover:opacity-70"
                                      onClick={() => {
                                        setEditCommentText(reply.comment);
                                        setEditingCommentId(reply._id);
                                        setDropdownVisible((prev) => ({
                                          ...prev,
                                          [reply._id]: false,
                                        }));
                                      }}
                                    >
                                      <FaEdit />
                                      Edit
                                    </button>
                                    <button
                                      className="flex w-full items-center gap-x-2 border-t border-[#999999] border-opacity-20 px-4 py-2 text-left text-[#ac3737] hover:opacity-70"
                                      onClick={() =>
                                        handleDeleteComment(reply._id)
                                      }
                                    >
                                      <FaTrashAlt />
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {editingCommentId === reply._id ? (
                          <div className="flex justify-end">
                            <div className="mt-4 w-[98%] rounded-xl bg-secondary">
                              <textarea
                                className="hide-scrollbar h-20 w-full rounded-xl border-none bg-secondary p-3 text-base text-[#b6b6b6] placeholder-primary outline-none xl:text-[15px]"
                                rows={2}
                                onClick={() => setShowReplyOptions(true)}
                                value={editCommentText}
                                onChange={(e) =>
                                  setEditCommentText(e.target.value)
                                }
                              ></textarea>
                              <div className="comment-options flex w-full items-center justify-end gap-x-3 rounded-xl bg-secondary px-3 pb-3 text-base xl:text-sm ">
                                <div>
                                  <button
                                    className="w-24 rounded-full border border-primary p-2 text-[#b6b6b6]"
                                    onClick={() => {
                                      setEditingCommentId(null);
                                      setEditCommentText("");
                                      setShowReplyOptions(false);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="w-24 rounded-full border border-primary bg-[#b6b6b6] p-2 text-black"
                                    onClick={handleEditComment}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <p className="my-1 ml-10 break-before-all text-wrap text-base text-[#b6b6b6] xl:ml-8 xl:text-sm">
                            {reply.comment}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="mt-2 text-[15px] text-tertiary">No comments yet.</p>
          )}
        </div>
      </div>
      {showCreatePost && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-3xl rounded-lg bg-primary p-6">
            <button
              className="absolute right-6 top-4 text-light"
              onClick={() => setShowCreatePost(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <CreatePost
              onPostCreated={handlePostCreated}
              onPostUpdated={handlePostUpdated}
              postToEdit={postToEdit}
              onClose={() => setShowCreatePost(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostDetail;
