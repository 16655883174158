import {
  FaBookmark,
  FaHeart,
  FaLink,
  FaRegBookmark,
  FaRegComment,
  FaRegEye,
  FaRegHeart,
  FaShareAlt,
  FaUserCircle,
} from "react-icons/fa";
import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { formatRelativeTime } from "../utils/dateUtils";
import Purchases from "./Purchases";
import Sessions from "./Sessions";

interface Post {
  _id: string;
  username: string;
  title: string;
  body: string;
  userId: string;
  likes: number;
  views: number;
  commentCount: number;
  status: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  liked: boolean;
  bookmarked: boolean;
}

const { REACT_APP_API_URL, REACT_APP_URL } = process.env;

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const username = useSelector((state: RootState) => state.auth.username);
  const email = useSelector((state: RootState) => state.auth.profile?.email);
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<
    "myPosts" | "bookmarks" | "purchases" | "sessions"
  >(location.state?.activeTab || "myPosts");
  const [shareDropDownOpen, setShareDropDownOpen] = useState<string | null>(
    null,
  );
  const shareDropdownRef = useRef<HTMLDivElement>(null);

  const fetchPosts = useCallback(async () => {
    try {
      setLoading(true);
      const endpoint =
        activeTab === "myPosts" ? "/post/my-posts" : "/post/saved";
      const response = await axios.get(`${REACT_APP_API_URL}${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPosts(response.data.data);
    } catch (error) {
      setError("Failed to fetch posts");
    } finally {
      setLoading(false);
    }
  }, [activeTab, token]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (activeTab !== "purchases" && activeTab !== "sessions") {
      fetchPosts();
    }
  }, [activeTab, fetchPosts, token, navigate]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareDropdownRef.current &&
        !shareDropdownRef.current.contains(event.target as Node)
      ) {
        setShareDropDownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shareDropDownOpen]);

  const handleShareClick = (postId: string) => {
    if (shareDropDownOpen === postId) {
      setShareDropDownOpen(null);
    } else {
      setShareDropDownOpen(postId);
    }
  };

  const handleCopyLink = (postId: string) => {
    const post = posts.find((p) => p._id === postId);
    if (post) {
      navigator.clipboard
        .writeText(`${REACT_APP_URL}/space/post/${post._id}`)
        .then(() => {
          toast.success("Link copied to clipboard!");
          setShareDropDownOpen(null);
        })
        .catch((error) => {
          console.error("Failed to copy link:", error);
          toast.error("Failed to copy link. Please try again.");
        });
    } else {
      toast.error("Post not found.");
    }
  };

  const handleLike = async (postId: string) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    try {
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
                ...post,
                liked: !post.liked,
                likes: post.liked ? post.likes - 1 : post.likes + 1,
              }
            : post,
        ),
      );
      await axios.post(
        `${REACT_APP_API_URL}/post/like/${postId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      toast.error("Failed to like the post. Please try again later.");
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? {
                ...post,
                liked: !post.liked,
                likes: post.liked ? post.likes - 1 : post.likes + 1,
              }
            : post,
        ),
      );
    }
  };

  const handleBookmark = async (postId: string) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    try {
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post._id === postId) {
            const newBookmarkedStatus = !post.bookmarked;
            toast.success(
              newBookmarkedStatus
                ? "Added to your Bookmarks"
                : "Removed from your Bookmarks",
            );
            return { ...post, bookmarked: newBookmarkedStatus };
          }
          return post;
        }),
      );
      await axios.post(
        `${REACT_APP_API_URL}/post/bookmark/${postId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      toast.error("Failed to bookmark the post. Please try again later.");
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post._id === postId) {
            const newBookmarkedStatus = !post.bookmarked;
            return { ...post, bookmarked: newBookmarkedStatus };
          }
          return post;
        }),
      );
    }
  };

  return (
    <div className="m-auto h-auto min-h-screen w-full rounded-lg border-primary bg-primary font-community drop-shadow xl:mx-5 xl:h-screen xl:w-[60%] xl:border">
      <div className="flex items-center justify-start pb-5 pl-2 text-white xl:py-5">
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className="cursor-pointer pl-5 pr-3 "
          onClick={() => navigate("/space")}
        />
        <h2 className="text-lg tracking-wide">Profile</h2>
      </div>
      <div className="border-t border-primary"></div>
      <div className="flex bg-secondary">
        <div className="flex items-center justify-start px-5 pb-4 pt-5">
          <FaUserCircle className="mr-5 text-6xl text-light" />
          <div>
            <p className="text-lg font-semibold text-light">{username}</p>
            <p className="text-sm text-tertiary">{email}</p>
          </div>
        </div>
      </div>{" "}
      <div className="bg-secondary ">
        <div className="flex flex-wrap items-center justify-start space-y-2 px-6 pb-5 text-[15px] xl:space-x-3 xl:space-y-0">
          <button
            className={`rounded-full px-4 py-2 text-[15px] ${
              activeTab === "myPosts" ? "bg-light text-primary" : "text-light"
            }`}
            onClick={() => setActiveTab("myPosts")}
          >
            My Posts
          </button>
          <button
            className={`rounded-full px-4 py-2 text-[15px] ${
              activeTab === "bookmarks" ? "bg-light text-primary" : "text-light"
            }`}
            onClick={() => setActiveTab("bookmarks")}
          >
            Bookmarks
          </button>
          <button
            className={`rounded-full px-4 py-2 text-[15px] ${
              activeTab === "purchases" ? "bg-light text-primary" : "text-light"
            }`}
            onClick={() => setActiveTab("purchases")}
          >
            Purchases
          </button>
          <button
            className={`rounded-full px-4 py-2 text-[15px] ${
              activeTab === "sessions" ? "bg-light text-primary" : "text-light"
            }`}
            onClick={() => setActiveTab("sessions")}
          >
            Sessions
          </button>
        </div>
      </div>
      <div className="border-t border-primary"></div>
      {activeTab === "purchases" ? (
        <Purchases />
      ) : activeTab === "sessions" ? (
        <Sessions />
      ) : (
        <div className="hide-scrollbar h-[70%] bg-primary px-4 pt-4 xl:overflow-y-scroll xl:pb-32">
          {loading ? (
            <div className="mt-20 flex h-full items-center justify-center xl:mt-0">
              <ClipLoader size={50} color={"#ffffff"} loading={loading} />
            </div>
          ) : error ? (
            <div className="text-center text-base text-light">{error}</div>
          ) : posts.length === 0 ? (
            <div className="text-center text-[15px] text-light xl:mt-5">
              {activeTab === "myPosts"
                ? "You haven't created any posts yet!"
                : "No bookmarks yet."}
            </div>
          ) : (
            posts.map((post) => (
              <div
                key={post._id}
                className="mb-3 cursor-pointer rounded-xl border border-primary bg-primary px-4 pt-4 shadow-md"
                onClick={() => navigate(`/space/post/${post._id}`)}
              >
                <div className="mb-2 flex items-center justify-between">
                  <div className="flex items-center">
                    <FaUserCircle className="mr-2 h-6 w-6 text-light" />
                    <span className="font-semibold text-light">
                      {post.username ? post.username : "Anonymous"}
                    </span>
                  </div>
                  <div className="flex items-center text-tertiary">
                    <span className="mr-2 text-[12px] text-tertiary">
                      {formatRelativeTime(post.createdAt)}
                    </span>
                    <div
                      className="text-tertiary hover:scale-105 hover:opacity-70"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBookmark(post._id);
                      }}
                    >
                      {post.bookmarked ? (
                        <FaBookmark className="mr-1 inline text-light" />
                      ) : (
                        <FaRegBookmark className="mr-1 inline" />
                      )}
                    </div>
                  </div>
                </div>
                <h2 className="text-base font-medium text-title">
                  {post.title}
                </h2>
                <p className="mt-2 line-clamp-2  text-sm tracking-wide text-tertiary">
                  {post.body}
                </p>
                <div className="flex items-center justify-between gap-x-3 py-2 text-sm">
                  <div className="flex items-center gap-x-3">
                    <div
                      className="my-2 flex h-fit w-16 items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(post._id);
                      }}
                    >
                      {post.liked ? (
                        <FaHeart className="mr-1 inline  text-light" />
                      ) : (
                        <FaRegHeart className="mr-1 inline" />
                      )}{" "}
                      {post.likes}
                    </div>
                    <div className="flex h-fit w-16 items-center justify-between gap-x-1 rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70">
                      <FaRegComment className="mr-1 inline" />{" "}
                      {post.commentCount}
                    </div>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <div className="flex h-fit w-[70px] items-center justify-between rounded-full border border-primary px-3 py-2 text-tertiary hover:scale-105 hover:opacity-70">
                      <FaRegEye className="mr-1 inline" /> {post.views}
                    </div>
                    <div className="relative h-fit w-14 rounded-full border border-primary px-3 text-tertiary">
                      <div
                        className="flex cursor-pointer items-center justify-center py-3 text-left"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShareClick(post._id);
                        }}
                      >
                        <FaShareAlt className="inline" />
                      </div>
                      {shareDropDownOpen === post._id && (
                        <div
                          ref={shareDropdownRef}
                          className="absolute right-0 top-0 w-40 origin-top-right rounded-md bg-secondary shadow-lg"
                        >
                          <div
                            className="flex cursor-pointer items-center gap-x-2 rounded-md px-4 py-3 text-tertiary hover:opacity-90"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyLink(post._id);
                            }}
                          >
                            <FaLink />
                            Copy Link
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default UserProfile;
